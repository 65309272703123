import React, { useState } from "react";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import {
  ActionLabel,
  CopySubjectIcon,
  ItemMenu,
  MenuButton,
  MenuContainer,
  MenuPopover,
} from "./FindingScanningMenu.styled";
import {
  copyFindingSubject,
  removeFindingScanningSubject,
  setFindingsChanged,
  setNewSubjectName,
} from "../../../../../store/actions/findings/findingsActions";
import { useDispatch, useSelector } from "react-redux";
import { toggleRenameScanningSubjectModal } from "../../../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import { selectMyProfileId } from "../../../../../store/selectors/loginSelectors";

const FindningScanningMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectMyProfileId);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditSubject = () => {
    setAnchorEl(null);
    setOpen(false);
    dispatch(
      toggleRenameScanningSubjectModal({
        title: t("scanningObjects.editScanningObject.title"),
        rank: 1,
        isEditing: true,
        subjectId: props?.sectionId,
        sectionData: props?.sectionData,
        handleSubmit: ({ specificName }) => {
          dispatch(
            setNewSubjectName({
              subjectId: props?.sectionId,
              specificName,
            })
          );
        },
      })
    );
  };

  const handleCopySubject = () => {
    setAnchorEl(null);
    setOpen(false);
    dispatch(
      toggleRenameScanningSubjectModal({
        title: t("scanningObjects.editScanningObject.copySubject"),
        rank: 1,
        isEditing: true,
        isCopying: true,
        subjectId: props?.sectionId,
        sectionData: props?.sectionData,
        handleSubmit: ({ specificName }) => {
          dispatch(
            copyFindingSubject({
              subjectId: props?.sectionId,
              specificName,
              userId,
            })
          );
        },
      })
    );
  };

  const handleDeleteSubject = (event, subjectId) => {
    event.stopPropagation();
    dispatch(setFindingsChanged(true));
    dispatch(removeFindingScanningSubject(subjectId));
    handleClose();
  };

  return (
    <MenuContainer>
      <MenuButton
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </MenuButton>
      <MenuPopover
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "6px",
          },
        }}
      >
        <ItemMenu
          onClick={handleEditSubject}
          sx={{ padding: "6px 24px 6px 24px" }}
        >
          <EditIcon /> <ActionLabel>{t("common.change")}</ActionLabel>
        </ItemMenu>
        <ItemMenu
          onClick={handleCopySubject}
          sx={{ padding: "6px 24px 6px 24px" }}
        >
          <CopySubjectIcon /> <ActionLabel>{t("common.copy")}</ActionLabel>
        </ItemMenu>
        <ItemMenu
          onClick={(event) => handleDeleteSubject(event, props?.sectionId)}
          sx={{ padding: "6px 24px 6px 24px" }}
        >
          <DeleteIcon /> <ActionLabel>{t("common.delete")}</ActionLabel>
        </ItemMenu>
      </MenuPopover>
    </MenuContainer>
  );
};

FindningScanningMenu.propTypes = {
  sectionId: PropTypes.object,
  onClick: PropTypes.func,
  sectionData: PropTypes.object,
};

export default FindningScanningMenu;
