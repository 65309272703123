import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  clearCommonTemplates,
  fetchCommonTemplates,
} from "../../../../store/actions/commonTemplates/commonTemplatesActions";
import {
  selectCommonTemplatesData,
  selectTotalCommonTemplates,
} from "../../../../store/selectors/commonTemplatesSelector";
import commonTemplatesTableConstants from "../../../../constants/tableConstants/commonTemplatesTableConstants";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import Table from "../../../Table/Table";

const TemplatesTable = ({onRowClick}) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);


  const handleRowClick = (rowData) => {
    onRowClick(rowData)
  };


  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(commonTemplatesTableConstants).map(
      (property) => {
        return {
          ...commonTemplatesTableConstants[property],
          field: t(commonTemplatesTableConstants[property].i18key),
          propertyName: property,
          backendProperty:
            commonTemplatesTableConstants[property]?.backendProperty ||
            property,
          style: commonTemplatesTableConstants[property]?.style,
          renderer:
            commonTemplatesTableConstants[property]?.renderer || TextRenderer,
          rendererProps: { hideChangePassword: true },
        };
      }
    );
    return columnsObject;
  }, [commonTemplatesTableConstants, t]);

  const mapDataFunction = useCallback((newData) => {
    return newData.map((singleData) => {
      let mappedDataObject = {
        caption: singleData.scanningSubjectCaption,
        id: singleData?.id,
      };
      return mappedDataObject;
    });
  }, []);
  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      clickable
      hideSearch
      onRowClick={handleRowClick}
      hideAddButton
      searchPlaceholder={"Searching Common Templates"}
      dataSelector={selectCommonTemplatesData}
      totalDataSelector={selectTotalCommonTemplates}
      dispatchFunction={fetchCommonTemplates}
      mapDataFunction={mapDataFunction}
      clearDispatchFunction={clearCommonTemplates}
      onClick={() => handleRowClick()}
      isSavedInHistory ={false}
    />
  );
};

TemplatesTable.propTypes = {
  children: PropTypes.node,
  onRowClick:PropTypes.any
};

export default TemplatesTable;
