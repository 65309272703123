import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleTablePreviewModal } from "../../../store/actions/modal/modalActions";
import {
  // CancelButton,
  CloseButton,
  ModalTitle,
} from "../Modal.styled";
import {
  TablePreviewModalContainer,
  ButtonsContainer,
  TablePreviewContainer,
} from "./TablePreviewModal.styled";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import FindingItemTableHeader from "../../Cards/FindingQuestionCard/FindingTableInput/FindingItemTable/FindingItemTableHeader/FindingItemTableHeader";
import FindingItemTableRow from "../../Cards/FindingQuestionCard/FindingTableInput/FindingItemTable/FindingItemTableRow/FindingItemTableRow";
import { useMemo } from "react";
import { CancelButton, SaveButton } from "../SetScanningObjectModal/SetScanningObjectModal.styled";
import { useState } from "react";
import { AddRowButton, PlusIconThin } from "../../Cards/FindingQuestionCard/FindingTableInput/FindingTableInput.styled";

const TablePreviewModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(toggleTablePreviewModal());
  };

  const columns = useMemo(() => {
    return props?.columns?.map?.((singleColumn) => ({
      ...singleColumn,
      answers: [{ answer: singleColumn?.defaultAnswer }],
    }));
  }, [props?.columns]);

  const [newRows, setRow] = useState(props.rows)

  const handleSaveModal = () => {
    setRow(newRows)
    props.onSubmit(newRows)
    dispatch(toggleTablePreviewModal());
  }

  const handleAddRow = () => {
    var maxIndex = newRows.length > 0 ? (Math.max(...newRows?.map?.((o) => o?.rowIndex)) || 0) : -1
    props?.columns?.map?.((singleColumn) => {
      setRow((prevRow) => [
        ...prevRow,
        {
          rowIndex: maxIndex + 1,
          columnIndex: singleColumn.columnIndex,
          answer: ""
        }
      ])
    })
  }

  const sortRowsByColumnIndex = (rows) => {
    const columns = props?.columns;
    let uniqueColumnTitles = [
      ...new Set(columns?.map?.((singleColumn) => singleColumn?.columnTitle)),
    ];
    const groupedColumnsByTitle = uniqueColumnTitles?.map?.((singleUniqueColumnTitle) =>
      columns?.filter?.(
        (singleColumn) => singleColumn?.columnTitle === singleUniqueColumnTitle
      )
    );

    const columnIndexes = groupedColumnsByTitle.flatMap((col) => col.map((c) => c.columnIndex));
  
    // Mapiranje kolona na njihove indekse
    var i = 0;
    const columnIndexMap = {};
    groupedColumnsByTitle.forEach((group) => {
      group.forEach((column) => {
        columnIndexMap[i++] = column;
      });
    });

    // Sortiranje redova na osnovu indeksa kolone
    rows.sort((a, b) => {
      const columnIndexA = columnIndexes.indexOf(a.columnIndex);
      const columnIndexB = columnIndexes.indexOf(b.columnIndex);
      return columnIndexA - columnIndexB;
    });

    return rows;
  };

  sortRowsByColumnIndex(newRows);
  //Pravljenje dictionary na onsovu rowIndex-a
  const rowsMap = newRows.reduce((acc, { rowIndex, columnIndex, answer }) => {
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push({ columnIndex, answer });
    return acc;
  }, {});

  const handleCellChange = (rowIndex, columnIndex, newValue) => {
    setRow((prevRows) =>
      prevRows.map((row) =>
        row.rowIndex === parseInt(rowIndex) && row.columnIndex === parseInt(columnIndex)
          ? { ...row, answer: newValue }
          : row
      )
    );
  };

  const handleRemoveRow = (rowIndex) => {
    setRow((prevRows) => {
      const filteredRows = prevRows.filter((row) => row.rowIndex !== parseInt(rowIndex));
  
      // Azuriranje rowIndex za redove koji slede nakon reda koji je obrisan
      const updatedRows = filteredRows.map((row) => {
        if (row.rowIndex > parseInt(rowIndex)) {
          return { ...row, rowIndex: row.rowIndex - 1 };
        }
        return row;
      });
  
      return updatedRows;
    });
  }

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="absolute"
        />
      ) : (
        <></>
      )}
      <TablePreviewModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{t("tablePreview.title")}</ModalTitle>
        <TablePreviewContainer>
          <FindingItemTableHeader
            columns={columns}
            readonly
            otherRows={
              <>
                {Object.keys(rowsMap).map(index => (
                  <FindingItemTableRow
                    useForScanningSections={true}
                    key={index}
                    readonly={false}
                    rowIndex={index}
                    lastRow
                    data={rowsMap[index].map((singleColumn) => ({
                      ...singleColumn,
                      answer: singleColumn?.answer,

                    }))}
                    onCellChange={handleCellChange}
                    removeRow={handleRemoveRow}
                  />
                ))}
              </>
            }
          />
        </TablePreviewContainer>
        <AddRowButton onClick={handleAddRow} >
          <PlusIconThin />
          {t("table.addRow")}
        </AddRowButton>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal} >
            {t("common.close")}
          </CancelButton>
          <SaveButton onClick={handleSaveModal}>
            {t("common.save")}
          </SaveButton>
        </ButtonsContainer>
      </TablePreviewModalContainer>
    </>
  );
};

TablePreviewModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  columns: PropTypes.array,
  rows: PropTypes.array
};

export default TablePreviewModal;
