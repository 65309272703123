import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { HOME_PAGE } from "../../../constants/pages";
import loginInitialValues, {
  loginFormikConstants,
} from "../../../initialValues/loginInitialValues";
import {
  clearLoginErrors,
  fetchUser,
} from "../../../store/actions/login/loginActions";
import { selectLoginError } from "../../../store/selectors/loginSelectors";
import history from "../../../store/utils/history";
import loginValidationSchema from "../../../validations/loginValidationSchema";
import EyeIcon from "../../Icon/Icons/Eye";
import EyeStrikedIcon from "../../Icon/Icons/EyeStriked";
import {
  CheckboxContainer,
  ErrorMessage,
  InputContainer,
  InputField,
  InputLabel,
  InputRightElement,
  LoginButton,
  LoginFormContainer,
} from "./LoginForm.styled";
import LanguageDropdown from "../../Dropdown/LanguageDropdown/LanguageDropdown";

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(selectLoginError);
  const [showPassword, setShowPassword] = useState(false);
  const [triedToLogin, setTriedToLogin] = useState(false);

  const handleApiResponseSuccess = () => {
    dispatch(clearLoginErrors());
    history.push({
      pathname: HOME_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  useEffect(() => {
    dispatch(clearLoginErrors());
  }, []);

  const handleApiResponseError = () => {
    formik?.setFieldValue?.(loginFormikConstants.password, "");
    setTriedToLogin(true);
  };

  const handleSubmit = (values) => {
    const { userName, password } = values;
    dispatch(
      fetchUser({
        values: {
          userName,
          password,
        },
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleChangePassword = (event) => {
    formik?.handleChange(event);
    if (triedToLogin) setTriedToLogin(false);
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <LoginFormContainer component="form" onSubmit={formik?.handleSubmit}>
      <InputContainer>
        <InputLabel>{t("login.emailLabel")}</InputLabel>
        <InputField data-qa="email-input"
          id={loginFormikConstants.username}
          onChange={formik?.handleChange}
          placeholder={t("login.emailPlaceholder")}
        />
        {formik?.errors?.[loginFormikConstants.username] &&
          formik?.touched?.[loginFormikConstants.username] && (
            <ErrorMessage>
              {t(formik?.errors?.[loginFormikConstants.username])}
            </ErrorMessage>
          )}
      </InputContainer>
      <InputContainer>
        <InputLabel>{t("login.passwordLabel")}</InputLabel>
        <InputField data-qa="password-input"
          type={!showPassword && "password"}
          id={loginFormikConstants.password}
          value={formik?.values?.[loginFormikConstants.password]}
          onChange={handleChangePassword}
        />
        {formik?.errors?.[loginFormikConstants.password] &&
        formik?.touched?.[loginFormikConstants.password] &&
        !triedToLogin ? (
          <ErrorMessage>
            {t(formik?.errors?.[loginFormikConstants.password])}
          </ErrorMessage>
        ) : (
          error && <ErrorMessage>{t(error)}</ErrorMessage>
        )}
        <InputRightElement
          onClick={() => setShowPassword((prevValue) => !prevValue)}
          isLogin={true}
        >
          {showPassword ? <EyeStrikedIcon /> : <EyeIcon />}
        </InputRightElement>
      </InputContainer>
      <CheckboxContainer>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              checked={formik?.values?.rememberMe}
              id={loginFormikConstants.rememberMe}
              onChange={formik?.handleChange}
            />
          }
          label={
            <InputLabel style={{ marginBottom: 0 }}>
              {t("login.rememberMeLabel")}
            </InputLabel>
          }
        />
        <LanguageDropdown data-qa="language-dd"/>
      </CheckboxContainer>
      <LoginButton component="button" type="submit" data-qa="login-submit-button">
        {t("login.loginButton")}
      </LoginButton>
    </LoginFormContainer>
  );
};

LoginForm.propTypes = {
  children: PropTypes.node,
};

export default LoginForm;
