export default {
  caption: {
    id: 0,
    i18key: "common.name",
    style: {
      minWidth: "140px",
    },
    backendProperty: "caption",
    redirect: true,
    setMaxHeight: true,
    disableSort: true,
  },
  header: {
    id: 2,
    i18key: "commonTemplates.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
};
