import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SubjectPreviewContainer, SubjectPreviewIframe } from "./SubjectPreview.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectCommonTemplatePreview } from "../../../../store/selectors/commonTemplatesSelector";
import { fetchCommonTemplatePreview } from "../../../../store/actions/commonTemplates/commonTemplatesActions";

const SubjectPreview = ({valueId}) => {
  const dispatch = useDispatch();
  const commonTemplatePreview = useSelector(selectCommonTemplatePreview);
  const [value, setNewValue] = useState(null)

  //console.log("Vrednost je sada:",valueId);

  useEffect(() => {
    if(valueId===null) {
      setNewValue(1);
    }else {
    dispatch(fetchCommonTemplatePreview({ commonTemplateId:  valueId.id }));
    }
  }, [valueId, value]);

  //console.log({commonTemplatePreview});

  return (
    <SubjectPreviewContainer>
      <SubjectPreviewIframe srcDoc= {commonTemplatePreview}/> 
    </SubjectPreviewContainer>
  );
};

SubjectPreview.propTypes = {
  children: PropTypes.node,
  valueId:PropTypes.any,
};

export default SubjectPreview;
