import {
  COMMON_TEMPLATES_ERROR,
  COMMON_TEMPLATES_FETCH,
  COMMON_TEMPLATES_SUCCESS,
  COMMON_TEMPLATES_SET,
  SINGLE_COMMON_TEMPLATE_ERROR,
  SINGLE_COMMON_TEMPLATE_FETCH,
  SINGLE_COMMON_TEMPLATE_SUCCESS,
  SINGLE_COMMON_TEMPLATE_SET,
  COMMON_TEMPLATES_CREATE_ERROR,
  COMMON_TEMPLATES_CREATE_FETCH,
  COMMON_TEMPLATES_CREATE_SUCCESS,
  COMMON_TEMPLATES_EDIT_ERROR,
  COMMON_TEMPLATES_EDIT_FETCH,
  COMMON_TEMPLATES_EDIT_SUCCESS,
  COMMON_TEMPLATES_CLEAR,
  COMMON_TEMPLATES_SET_TOTAL,
  COMMON_TEMPLATES_DELETE_FETCH,
  COMMON_TEMPLATES_DELETE_SUCCESS,
  COMMON_TEMPLATES_DELETE_ERROR,
  COMMON_TEMPLATES_SET_ERROR_MESSAGE,
  PREVIEW_COMMON_TEMPLATES_SET,
  PREVIEW_COMMON_TEMPLATES_SUCCESS,
  PREVIEW_COMMON_TEMPLATES_ERROR,
  PREVIEW_COMMON_TEMPLATES_FETCH,
} from "./commonTemplatesActionConstants.js";

export const setCommonTemplatePreview = (payload) => ({
  type: PREVIEW_COMMON_TEMPLATES_SET,
  payload,
}) 

export const fetchCommonTemplatePreviewSuccess = (payload) => ({
  type: PREVIEW_COMMON_TEMPLATES_SUCCESS,
  payload,
})
export const fetchCommonTemplatePreviewError = (payload) => ({
  type: PREVIEW_COMMON_TEMPLATES_ERROR,
  payload,
})
export const fetchCommonTemplatePreview = (payload) => ({
  type: PREVIEW_COMMON_TEMPLATES_FETCH,
  payload,
});
export const fetchCommonTemplates = (payload) => ({
  type: COMMON_TEMPLATES_FETCH,
  payload,
});
export const fetchCommonTemplatesSuccess = (payload) => ({
  type: COMMON_TEMPLATES_SUCCESS,
  payload,
});
export const fetchCommonTemplatesError = (payload) => ({
  type: COMMON_TEMPLATES_ERROR,
  payload,
});
export const fetchCreateCommonTemplate = (payload) => ({
  type: COMMON_TEMPLATES_CREATE_FETCH,
  payload,
});
export const fetchCreateCommonTemplateSuccess = (payload) => ({
  type: COMMON_TEMPLATES_CREATE_SUCCESS,
  payload,
});
export const fetchCreateCommonTemplateError = (payload) => ({
  type: COMMON_TEMPLATES_CREATE_ERROR,
  payload,
});
export const fetchEditCommonTemplate = (payload) => ({
  type: COMMON_TEMPLATES_EDIT_FETCH,
  payload,
});
export const fetchEditCommonTemplateSuccess = (payload) => ({
  type: COMMON_TEMPLATES_EDIT_SUCCESS,
  payload,
});
export const fetchEditCommonTemplateError = (payload) => ({
  type: COMMON_TEMPLATES_EDIT_ERROR,
  payload,
});

export const fetchDeleteCommonTemplate = (payload) => ({
  type: COMMON_TEMPLATES_DELETE_FETCH,
  payload,
});
export const fetchDeleteCommonTemplateSuccess = (payload) => ({
  type: COMMON_TEMPLATES_DELETE_SUCCESS,
  payload,
});
export const fetchDeleteCommonTemplateError = (payload) => ({
  type: COMMON_TEMPLATES_DELETE_ERROR,
  payload,
});

export const fetchSingleCommonTemplate = (payload) => ({
  type: SINGLE_COMMON_TEMPLATE_FETCH,
  payload,
});
export const fetchSingleCommonTemplateSuccess = (payload) => ({
  type: SINGLE_COMMON_TEMPLATE_SUCCESS,
  payload,
});
export const fetchSingleCommonTemplateError = (payload) => ({
  type: SINGLE_COMMON_TEMPLATE_ERROR,
  payload,
});
export const setCommonTemplates = (payload) => ({
  type: COMMON_TEMPLATES_SET,
  payload,
});
export const setCommonTemplatesTotal = (payload) => ({
  type: COMMON_TEMPLATES_SET_TOTAL,
  payload,
});
export const clearCommonTemplates = () => ({
  type: COMMON_TEMPLATES_CLEAR,
});

export const setSingleCommonTemplate = (payload) => ({
  type: SINGLE_COMMON_TEMPLATE_SET,
  payload,
});
export const setCommonTemplatesErrorMessage = (payload) => ({
  type: COMMON_TEMPLATES_SET_ERROR_MESSAGE,
  payload,
});
