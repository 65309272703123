import { getRequest, replaceInUrl, postRequest } from ".";
import  apiEndpoints  from "./apiEndpoints";

export const attemptFetchCommonTemplates = (payload) =>
  getRequest(apiEndpoints.commonTemplate.getCommonTemplates, payload);

export const attemptFetchCommonTemplatePreview = (payload) =>
getRequest(
  replaceInUrl(apiEndpoints.commonTemplate.getCommonTemplatePreview, {
    commonTemplateId: payload,
  })
);

export const attemptFetchSingleCommonTemplate = (payload) => 
  getRequest(replaceInUrl(apiEndpoints.commonTemplate.getCommonTemplate, {
    commonTemplateId: payload,
  })
);

export const attemptFetchCreateCommonTemplate = (payload) =>
  postRequest(apiEndpoints.commonTemplate.importCommonTemplate, payload);
